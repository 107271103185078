import * as styled from './styles'

import { Empty, Skeleton, Tabs } from 'antd'
import React, { Suspense, useContext, useEffect, useState } from 'react'
import {
  checkFeatureFlag,
  makeToastError,
  reactSelectStyles,
} from '../../services/utils'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import ActivityFeed from '../Candidate/ActivityFeed'
import CandidateScoringTable from '../Candidate/CandidateScoringTable'
import ConversationSpider from '../Candidate/ConversationSpider'
import EmailModal from '../EmailModal'
import { FeathersContext } from '../../services/feathers'
import Files from '../Candidate/Files'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import HeaderCandidateCard from '../HeaderCandidateCard'
import ListApplications from './ListApplications'
import ListOpportunities from './ListOpportunities'
import Loader from '../Loader'
import Notes from '../Candidate/Notes'
import PresentationNotes from '../Candidate/PresentationNotes'
import ProgrammedAutomaticMail from '../ProgrammedAutomaticMail'
import PropTypes from 'prop-types'
import TalentsInfo from '../Candidate/TalentsInfo/TalentsInfo'
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const { TabPane } = Tabs

/**
 * CandidateOnly component made to display a candidate card
 * @param {string} candidateId Id of the candidate to show
 * @param {string} display Type of display
 * @param {string} defaultSelectedTab tab selected by default
 * @param {function} updateCandidateInParent function to call to update the parent data
 */
const CandidateOnly = ({
  mailOnReject,
  candidateId,
  display,
  updateCandidateInParent,
  conversation,
  offer,
  openResume,
  refreshParentData,
  handleClassifyCandidate,
  handleReject,
  defaultSelectedTab,
  onClose,
}) => {
  const { t } = useTranslation(['talent', 'common'], { useSuspense: false })
  const dispatch = useDispatch()
  const history = useHistory()
  const queryClient = useQueryClient()

  const feathers = useContext(FeathersContext)

  const user = useSelector((state) => state.user)
  const featuresAccess = user.company_id?.featuresAccess || ['crm']

  const [processStatusOptions, setProcessStatusOptions] = useState([])
  const [updateProcess, setUpdateProcess] = useState({})

  const [numberOfDealbreakers, setNumberOfDealBreakers] = useState(0)
  const [modalEmail, setModalEmail] = useState(false)
  const [modalTrigger, setModalTrigger] = useState(false)
  const [waitingActions, _setWaitingActions] = useState(null)
  const [isLoadingSendEmail, setIsLoadingSendEmail] = useState(false)

  const [selectedProcessStatus, setSelectedProcessStatus] = useState(
    processStatusOptions[0],
  )

  useEffect(() => {
    if (!conversation || !offer) {
      return
    }

    const actions = offer?.actions
    let rejectedActions = []

    if (actions?.length) {
      const index = actions.map((e) => e.trigger).indexOf('notSelected')

      if (index >= 0) {
        const action = actions[index].actionsToTrigger[0]

        if (action?.text && action?.subject) {
          rejectedActions = [action]
        }
      }
    }

    const processStatusOptions = [
      { label: t('common:processStatusOptions.toProcess'), value: null },
      {
        label: t('common:processStatusOptions.notSelected'),
        value: 'notSelected',
        actions: rejectedActions,
      },
      { label: t('common:processStatusOptions.selected'), value: 'selected' },
      ...offer?.processStatus_id.map((status) => ({
        label: status.name,
        value: status._id,
        actions: status.actions,
        id: status._id,
      })),
    ]

    setProcessStatusOptions(processStatusOptions)
    const { classification, processStatus_id } = conversation

    let selectedProcessStatus = processStatusOptions[0]

    if (classification === 'SELECTED') {
      if (processStatus_id) {
        const selectedIndex = processStatusOptions
          .map((e) => e.value)
          .indexOf(processStatus_id._id)

        if (selectedIndex >= 0) {
          selectedProcessStatus = processStatusOptions[selectedIndex]
        } else {
          selectedProcessStatus = processStatusOptions[2]
        }
      } else {
        selectedProcessStatus = processStatusOptions[2]
      }
    } else if (classification === 'NOT_SELECTED') {
      selectedProcessStatus = processStatusOptions[1]
    }
    setSelectedProcessStatus(selectedProcessStatus)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offer, candidateId])

  const { data: processStatus, isFetching: isFetchingProcessStatus } = useQuery(
    {
      queryKey: 'processStatus',
      queryFn: () =>
        feathers.service('process-status').find({ query: { $limit: -1 } }),
      refetchOnWindowFocus: false,
    },
  )

  const {
    data: candidate,
    refetch: refetchCandidate,
    isFetching,
  } = useQuery({
    queryKey: ['candidate', candidateId],
    queryFn: async () => {
      const candidate = await feathers
        .service('candidatesfull')
        .get(candidateId)

      return candidate
    },
    refetchOnWindowFocus: false,
  })

  const {
    data: candidateFull,
    refetch: refetchCandidateFull,
    isFetching: isFetchingCandidateFull,
  } = useQuery({
    queryKey: ['candidatefull', candidateId],
    queryFn: async () => {
      const candidateFull = await feathers
        .service('candidates')
        .get(candidateId)
      return candidateFull
    },
    refetchOnWindowFocus: false,
  })

  const toggleTrigger = () => {
    /* if (!user.nylasActive) {
      AntdModal.warning({
        title: t('common:syncNylas.title'),
        width: 520,
        okText: t('common:syncNylas.btn'),
        okButtonProps: { className: 'basic-btn' },
        onOk: () => {
          dispatch(setUserId(user._id))
          history.push('/app/settings')
        },
      })
    } else {
      setModalTrigger(!modalTrigger)
    } */
    setModalTrigger(!modalTrigger)
  }

  const updateCandidateTags = useMutation(
    (tags) =>
      feathers.service('candidates').patch(candidateId, {
        tags: tags.map((tag) => tag.value) || [],
      }),
    {
      onSuccess: (candidate) => {
        refetchCandidate()
        // Want to replace this with a useEffect to execute when candidateId changes or on unmount
        updateCandidateInParent(candidate)
      },
      onError: (error) => {
        makeToastError(t('talent:updateTags.toasts.error'), error)
      },
    },
  )

  const handleChangeResearchStatus = useMutation(
    (researchStatus) =>
      feathers.service('candidates').patch(candidateId, { researchStatus }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('candidate')
        refetchCandidate()
      },
      onError: (error) => {
        makeToastError(t('talent:updateResearchStatus.toasts.error'), error)
      },
    },
  )

  const handleChangeProcessStatus = async (e) => {
    if (e === selectedProcessStatus) {
      return
    }

    setUpdateProcess(e)
    setSelectedProcessStatus(e)

    const status = e.value
    if (status === 'notSelected') {
      if (mailOnReject) {
        toggleTrigger()
        return
      }
      await handleReject(conversation, offer, mailOnReject)
      return
    }

    if (status === 'selected') {
      handleClassifyCandidate([conversation], null, offer, false)
        .then(() => {
          refreshParentData()
        })
        .catch((error) => {
          makeToastError(t('common:errorHappened'), error)
        })
    } else {
      const withAction = e?.actions?.length

      if (withAction) {
        setModalTrigger(true)
        return
      }
      handleClassifyCandidate([conversation], e, offer, false)
        .then(() => {
          refreshParentData()
        })
        .catch((error) => {
          makeToastError(t('common:errorHappened'), error)
        })
    }
  }

  const handlePreventTrigger = async () => {
    try {
      if (updateProcess.value === 'notSelected') {
        await handleReject(conversation, offer, false)

        if (display !== 'CV') {
          refreshParentData()
        }
      } else {
        await handleClassifyCandidate(
          [conversation],
          updateProcess,
          offer,
          false,
        )
      }

      if (display !== 'CV') {
        refreshParentData()
      }
      toggleTrigger()
    } catch (e) {
      console.error(e)
      makeToastError(t('common:errorHappened'), e)
    }
  }

  const handleEditTrigger = () => {
    toggleTrigger()
    setModalEmail(!modalEmail)
  }

  const handleTrigger = async () => {
    try {
      setIsLoadingSendEmail(true)
      const withAction = updateProcess?.actions?.length

      if (updateProcess.value === 'notSelected') {
        await handleReject(conversation, offer, mailOnReject)
      } else {
        await handleClassifyCandidate(
          [conversation],
          updateProcess,
          offer,
          withAction,
        )
      }
      toggleTrigger()
    } catch (e) {
      console.error(e)
    } finally {
      refreshParentData()
      setIsLoadingSendEmail(false)
    }
  }

  useEffect(() => {
    if (conversation && conversation.scoringVariables) {
      setNumberOfDealBreakers(
        conversation.scoringVariables.filter((el) => el.dealbreaker),
      )
    }
  }, [conversation])

  if (
    (isFetching && isFetchingCandidateFull && !candidate) ||
    isFetchingProcessStatus
  ) {
    return <Skeleton active />
  }

  if (!candidate) {
    return (
      <Empty
        description={t('talent:card.noSelectedCandidate')}
        style={{ marginTop: '40%' }}
      />
    )
  }

  // If there is a treatment status we will render a select in
  // headercandidatecard so we setup object with all the props
  let selectProps
  if (offer) {
    selectProps = {
      value: selectedProcessStatus,
      options: processStatusOptions,
      onChange: (e) => handleChangeProcessStatus(e),
      closeMenuOnSelect: true,
      isDisabled: featuresAccess.includes('guest'),
      styles: reactSelectStyles,
    }
  }

  const { likes = [], conversations = [], atsOffersApplied = [] } = candidate
  return (
    <Suspense fallback={<Loader text={t('common:loader.text')} />}>
      <styled.CandidateProfileWrapper data-cy="candidate-only-wrapper">
        {candidate && (
          <HeaderCandidateCard
            display={display}
            candidate={candidate}
            conversation={conversation}
            offer={offer}
            selectProps={selectProps}
            featuresAccess={featuresAccess}
            updateCandidateTags={(tags) => updateCandidateTags.mutate(tags)}
            handleChangeResearchStatus={handleChangeResearchStatus}
            updateCandidateInParent={updateCandidateInParent}
            refetchCandidate={refetchCandidate}
            openResume={openResume}
          />
        )}
        <Tabs
          size="small"
          defaultActiveKey={
            defaultSelectedTab === 'activity'
              ? 'activity'
              : conversation?.scoringVariables?.length
              ? 'general'
              : 'details'
          }
          style={{
            marginBottom: '10px',
            marginTop: '10px',
          }}
        >
          {featuresAccess.includes('crm') &&
            !!conversation &&
            offer.applicationType === 'full' &&
            conversation.scoringVariables?.length && (
              <TabPane
                tab={t('talent:applicationCard.tabs.prequalification')}
                key="general"
              >
                <div
                  style={{
                    marginTop: 20,
                  }}
                >
                  {offer && (
                    <div>
                      {/* <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 15,
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <HalfCircle conversation={conversation} width={80} />
                          {
                            displayApplicationScoreInfo(conversation.score, t)
                              .tag
                          }
                        </div>

                        <p style={{ fontSize: '14px', fontWeight: 600 }}>
                          {offer.name}
                        </p>
                      </div> */}
                      {numberOfDealbreakers > 0 && (
                        <p
                          style={{
                            fontSize: '12px',
                            fontWeight: '600',
                            color: '#d92550',
                          }}
                        >
                          <FontAwesomeIcon
                            style={{
                              fontSize: '13px',
                              cursor: 'pointer',
                              color: '#d92550',
                            }}
                            icon={faExclamationTriangle}
                          />{' '}
                          <span>
                            <span>{numberOfDealbreakers}</span> Dealbreaker
                          </span>
                        </p>
                      )}
                      <CandidateScoringTable conversation={conversation} />
                      <ConversationSpider
                        data={offer?.analytics?.scoringVariables}
                        conversation={conversation}
                      />
                    </div>
                  )}
                </div>
              </TabPane>
            )}
          {checkFeatureFlag(user?.company_id?._id) ? (
            <TabPane tab={t('common:details')} key="details">
              <TalentsInfo candidate={candidate} />
            </TabPane>
          ) : undefined}
          <TabPane
            tab={t('talent:applicationCard.tabs.activity')}
            key="activity"
          >
            <ActivityFeed
              candidate={candidateFull}
              conversation={conversation}
              processStatus={processStatusOptions}
              refreshParentData={refreshParentData}
            />
          </TabPane>
          <TabPane
            tab={t(
              [
                '5ede3f0a1746310018d42a3d', // J2BD
                '5fe0da154fbde1003fc656a3', // Develop
              ].includes(user?.company_id?._id)
                ? 'talent:card.tabs.discussions'
                : 'talent:card.tabs.notes',
            )}
            key="notes"
          >
            <Notes
              isCard={false}
              key={candidateFull?._id}
              notes={candidateFull?.notes || []}
              candidateId={candidateFull?._id}
              refetchCandidate={refetchCandidateFull}
            />
          </TabPane>
          {[
            '5ede3f0a1746310018d42a3d', // J2BD
            '5fe0da154fbde1003fc656a3', // Develop
          ].includes(user?.company_id?._id) ? (
            <TabPane
              tab={t('talent:card.tabs.presentationNote')}
              key="presentationNotes"
            >
              <PresentationNotes
                isCard={false}
                key={candidateFull?._id}
                presentationNotes={candidateFull?.presentationNotes || []}
                candidateId={candidateFull?._id}
                refetchCandidate={refetchCandidateFull}
              />
            </TabPane>
          ) : undefined}
          <TabPane tab={t('talent:applicationCard.tabs.files')} key="files">
            <Files
              isCard={false}
              key={candidate._id}
              files={candidate?.files}
              talentFiles={candidate?.talentFiles}
              candidateId={candidate?._id}
              refetchCandidate={refetchCandidate}
            />
          </TabPane>

          <TabPane tab={t('talent:card.tabs.applications')} key="conversations">
            {likes?.length > 0 ||
            conversations.length > 0 ||
            atsOffersApplied.length > 0 ? (
              <ListApplications
                applications={conversations}
                atsApplications={atsOffersApplied}
                externalRef={candidate.externalRef}
                refetchCandidate={refetchCandidate}
                processStatus={processStatus}
                onClose={onClose}
              />
            ) : (
              <div className="no-applications">
                <Empty description={t('common:noData')} />
              </div>
            )}
            {featuresAccess.includes('talent-pools') && (
              <ListOpportunities
                likes={likes.filter((e) =>
                  ['approved', 'pending'].includes(e.candidateStatus),
                )}
                key={candidateFull?._id}
                candidate={candidateFull}
                refetchCandidate={refetchCandidateFull}
              />
            )}
          </TabPane>
        </Tabs>

        {modalEmail && (
          <EmailModal
            isOpen={modalEmail}
            toggle={() => setModalEmail(!modalEmail)}
            conversationId={conversation._id}
            listCandidate={[candidate]}
            offer={offer}
            action={waitingActions}
            updateCandidateMessages={refetchCandidate}
            onSuccess={() => {
              handleClassifyCandidate(
                [conversation],
                updateProcess,
                offer,
                false,
              ).then(() => {
                refreshParentData()
              })
            }}
          />
        )}

        <ProgrammedAutomaticMail
          isOpen={modalTrigger}
          isLoadingSendEmail={isLoadingSendEmail}
          toggle={toggleTrigger}
          handleChoiceWithoutAction={handlePreventTrigger}
          handleEditTrigger={handleEditTrigger}
          handleChoiceWithAction={handleTrigger}
        />
      </styled.CandidateProfileWrapper>
    </Suspense>
  )
}

CandidateOnly.propTypes = {
  candidateId: PropTypes.string,
  display: PropTypes.string,
  updateCandidateInParent: PropTypes.func,
}

CandidateOnly.defaultProps = {
  updateCandidateInParent: () => {},
  candidateId: '',
  display: '',
}

export default CandidateOnly
