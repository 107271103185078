/* eslint-disable react/button-has-type */
import React, { lazy, Suspense, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import 'antd/dist/antd.css' // or 'antd/dist/antd.less'
import { RiCloseFill } from 'react-icons/ri'
import { Modal, Skeleton } from 'antd'
import toast from 'react-hot-toast'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useIntercom } from 'react-use-intercom'
import AppSidebar from '../Sidebar'
import NavBar from '../NavBar'
import DisconnectedComponent from '../../components/DisconnectedComponent'
import StartTrialModal from '../../components/StartTrialModal'
import TrialEndedModal from '../../components/TrialEndedModal'
import HelmetComponent from '../../components/Helmet'
import j2bdLogo from '../../assets/hollo/j2bd.svg'
import holloLogo from '../../assets/hollo/LogoLoginHollo.svg'

import AuthService from '../../services/AuthService'
import withAuth from '../../services/withAuth'

import nylasDisconnected from '../../assets/hollo/nylasDisconnected.svg'
import * as s from '../../routes/Onboarding/styles'
import { setUser } from '../../redux/actions/userActions'
import { setAmplitudeUserProperties } from '../../services/amplitude'

// Layout
const Dashboard = lazy(() => import('../../routes/Dashboard'))
const CreateOffer = lazy(() => import('../../routes/CreateOffer'))
const CreateTeam = lazy(() =>
  import('../../routes/Settings/Teams/ManageTeamForm'),
)
const CreateTree = lazy(() => import('../../routes/CreateTree'))
const CreateMandatoryTree = lazy(() =>
  import('../../routes/CreateMandatoryTree'),
)
const CreateReactivationTree = lazy(() =>
  import('../../routes/CreateReactivation'),
)
const Offers = lazy(() => import('../../routes/Offers'))
const Candidates = lazy(() => import('../../routes/Candidates'))
const Steps = lazy(() => import('../../routes/Steps'))
const Trees = lazy(() => import('../../routes/Trees'))
const MandatoryTrees = lazy(() => import('../../routes/MandatoryTrees'))
const Teams = lazy(() => import('../../routes/Settings/Teams'))
const Users = lazy(() => import('../../routes/Settings/Users'))
const ChatbotSettings = lazy(() => import('../../routes/ChatbotSettings'))
const Conversations = lazy(() => import('../../routes/Conversations'))
const Ats = lazy(() => import('../../routes/Ats'))
const Billing = lazy(() => import('../../routes/Billing'))
const Company = lazy(() => import('../../routes/Settings/Company'))
const Pipelines = lazy(() => import('../../routes/Pipelines'))
const Settings = lazy(() => import('../../routes/Settings'))
const ReengagementSettings = lazy(() =>
  import('../../routes/ReengagementSettings'),
)
const Pools = lazy(() => import('../../routes/Pools'))

// Admin Hollo Pages
const Companies = lazy(() => import('../../routes/Companies'))
const CompanyAdmin = lazy(() => import('../../routes/CompanyAdmin'))
const CreateCompany = lazy(() => import('../../routes/CreateCompany'))
const Deals = lazy(() => import('../../routes/Deals'))
const Usage = lazy(() => import('../../routes/Usage'))
const Templates = lazy(() => import('../../routes/Templates'))
const TemplatesClient = lazy(() => import('../../routes/TemplatesClient'))

const Auth = new AuthService()

const AppMain = (props) => {
  const { t } = useTranslation(['common'], {
    useSuspense: false,
  })
  const abortController = new AbortController()

  const { update } = useIntercom()

  const dispatch = useDispatch()

  const user = useSelector((state) => state.user)

  const [resyncNylasModal, setResyncNylasModal] = useState(false)

  useEffect(() => {
    update({
      name: `${user.firstname} ${user.lastname}`,
      email: user.email,
      created_at: parseInt(moment(user.createdAt).valueOf() / 1000, 10),
    })
  }, [user])

  useEffect(() => {
    if (user.nylasActive) {
      Auth.fetch('/nylas?type=grant', {
        signal: abortController.signal,
      }).catch((err) => {
        console.error(err)
        if (err.code !== 500) {
          setResyncNylasModal(true)
        }
      })
    }
  }, [])

  useEffect(() => {
    setAmplitudeUserProperties({
      Permissions: user?.permissions[0],
      Profile: user?.profile,
      CompanyId: user?.company_id?._id,
      NylasActive: user?.nylasActive,
      SubscribedToNotifications: user?.subscriptions?.length > 0,
      CareersPageActive: user?.company_id?.careersPageActive,
      Access: user?.company_id?.access,
      HasAts: !!user?.company_id?.ats_id,
      CurrentDeal:
        (!user?.company_id?.subscriptionCanceled ||
          moment(user?.company_id?.accessExpiresAt) > moment()) &&
        user?.company_id?.deal?.name,
    })
  }, [user])

  useEffect(() => () => abortController.abort(), [])

  const handleResyncLater = async () => {
    Auth.fetch(`/users/${user._id}?$populate[]=company_id`, {
      method: 'PATCH',
      body: JSON.stringify({
        nylasActive: false,
      }),
      signal: abortController.signal,
    })
      .then((updatedUser) => {
        dispatch(
          setUser({
            ...user,
            nylasActive: updatedUser.nylasActive,
          }),
        )
      })
      .catch((err) => {
        console.error(err)
      })

    setResyncNylasModal(false)
  }

  const handleAddNylasLink = async () => {
    try {
      // eslint-disable-next-line no-underscore-dangle
      localStorage.setItem('user_id', user._id)

      const link = await Auth.fetch('/nylas', {
        method: 'POST',
        body: JSON.stringify({
          action: 'authenticate',
          query: {
            address: user.email,
          },
        }),
        signal: abortController.signal,
      })
      setResyncNylasModal(false)
      window.open(link)
    } catch (e) {
      console.error(e)
      toast.error(
        t('common:errorResynchronizing', 'Erreur lors de la resynchronisation'),
      )
    }
  }

  let redirectTo = '/app/dashboard'

  if (user.permissions.includes('admin_hollo')) {
    redirectTo = '/app/companies'
  }

  let routesAccess

  const featuresAccess = user.company_id?.featuresAccess || ['crm']

  if (user.permissions.includes('admin_hollo')) {
    routesAccess = (
      <>
        <Route exact path="/app/companies" component={Companies} />
        <Route
          exact
          path="/app/companies/:companyId"
          component={CompanyAdmin}
        />
        <Route exact path="/app/new/company" component={CreateCompany} />

        <Route exact path="/app/deals" component={Deals} />
        <Route exact path="/app/companies/:companyId/usage" component={Usage} />
        <Route
          exact
          path="/app/companies/:companyId/offers"
          component={Offers}
        />
        <Route
          exact
          path={[
            '/app/companies/:companyId/new/offer',
            '/app/companies/:companyId/update/offer',
          ]}
          component={CreateOffer}
        />
        <Route exact path="/app/companies/:companyId/users" component={Users} />
        <Route exact path="/app/companies/:companyId/teams" component={Teams} />
        <Route
          exact
          path="/app/companies/:companyId/new/team"
          component={CreateTeam}
        />
        <Route exact path="/app/companies/:companyId/steps" component={Steps} />
        <Route exact path="/app/companies/:companyId/trees" component={Trees} />
        <Route
          exact
          path="/app/companies/:companyId/new/tree"
          component={CreateTree}
        />
        <Route
          exact
          path="/app/companies/:companyId/mandatorytrees"
          component={MandatoryTrees}
        />
        <Route
          exact
          path="/app/companies/:companyId/new/mandatorytree"
          component={CreateMandatoryTree}
        />
        <Route
          exact
          path="/app/companies/:companyId/new/reactivationtree"
          component={CreateReactivationTree}
        />
        <Route exact path="/app/companies/:companyId/ats" component={Ats} />
        <Route
          exact
          path="/app/companies/:companyId/pipelines"
          component={Pipelines}
        />
        <Route
          exact
          path="/app/companies/:companyId/reengagement-settings"
          component={ReengagementSettings}
        />
        <Route
          exact
          path="/app/companies/:companyId/chatbot-settings"
          component={ChatbotSettings}
        />
        <Route exact path="/app/steps" component={Steps} />
        <Route exact path="/app/trees" component={Trees} />
        <Route exact path="/app/mandatorytrees" component={MandatoryTrees} />
        <Route exact path="/app/messages" component={ReengagementSettings} />
        <Route exact path="/app/new/tree" component={CreateTree} />
        <Route
          exact
          path="/app/new/mandatorytree"
          component={CreateMandatoryTree}
        />
        <Route
          exact
          path="/app/new/reactivationtree"
          component={CreateReactivationTree}
        />
        <Route
          exact
          path="/app/companies/:companyId/settings"
          component={Settings}
        />
        <Route exact path="/app/settings" component={Settings} />
        <Route exact path="/app/chatbot-settings" component={ChatbotSettings} />
        <Route
          exact
          path="/app/companies/:companyId/templates"
          component={TemplatesClient}
        />
        <Route exact path="/app/templates" component={Templates} />
        <Route exact path="/app/pipelines" component={Pipelines} />
      </>
    )
  } else if (user.permissions.includes('guest')) {
    routesAccess = (
      <>
        <Route exact path="/app/dashboard" component={Dashboard} />
        <Route exact path="/app/offers" component={Offers} />
        <Route exact path="/app/offers/:id" component={Conversations} />
        <Route exact path="/app/settings" component={Settings} />
      </>
    )
  } else {
    const access = [
      { path: '/app/dashboard', component: Dashboard },
      { path: '/app/new/team', component: CreateTeam },
      { path: '/app/candidates', component: Candidates },
      { path: '/app/teams', component: Teams },
      { path: '/app/users', component: Users },
      { path: '/app/messages', component: ReengagementSettings },
      { path: '/app/billing', component: Billing },
      { path: '/app/company', component: Company },
      // { path: "/app/communication", component: CommunicationConsole },
      { path: '/app/settings', component: Settings },
      { path: '/app/reengagement-settings', component: ReengagementSettings },
      { path: '/app/chatbot-settings', component: ChatbotSettings },
    ]

    if (featuresAccess.includes('crm')) {
      access.push(
        ...[
          {
            path: ['/app/new/offer', '/app/update/offer'],
            component: CreateOffer,
          },
          { path: '/app/new/tree', component: CreateTree },
          { path: '/app/new/mandatorytree', component: CreateMandatoryTree },
          { path: '/app/offers', component: Offers },
          { path: '/app/steps', component: Steps },
          { path: '/app/trees', component: Trees },
          { path: '/app/offers/:id', component: Conversations },
          { path: '/app/mandatorytrees', component: MandatoryTrees },
          { path: '/app/pipelines', component: Pipelines },
        ],
      )
    }

    if (featuresAccess.includes('talent-pools')) {
      access.push(...[{ path: '/app/pools', component: Pools }])
    }

    routesAccess = (
      <>
        {access.map((e) => (
          <Route key={e.path} exact path={e.path} component={e.component} />
        ))}
      </>
    )
  }

  return (
    <div>
      <HelmetComponent
        faviconUrl={
          user?.company_id?._id === '5ede3f0a1746310018d42a3d'
            ? j2bdLogo
            : holloLogo
        }
        name={
          user?.company_id?._id === '5ede3f0a1746310018d42a3d'
            ? 'J2BD Partners'
            : 'Hollo'
        }
      />
      <NavBar {...props} />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__inner">
          <DisconnectedComponent />
          <StartTrialModal />
          <TrialEndedModal />
          <Suspense
            fallback={
              <div style={{ padding: '40px 20px', width: '700px' }}>
                <Skeleton active paragraph={{ rows: 1 }} />
              </div>
            }
          >
            <DisconnectedComponent />
            {routesAccess}

            <Route
              exact
              path="/app"
              render={() => <Redirect to={redirectTo} />}
            />
          </Suspense>
        </div>
      </div>

      {/* <Modal
        width={900}
        visible={resyncNylasModal}
        destroyOnClose
        closable={false}
        maskClosable={false}
        footer={null}
        closeIcon={<RiCloseFill size={32} />}
      >
        <s.OnboardingWrapper>
          <div className="onboarding-main">
            <img
              src={nylasDisconnected}
              style={{ margin: 'auto', marginBottom: '40px' }}
              height={150}
              alt="hello"
            />
            <h3>
              {t(
                'common:resynchronizeMailAndCalendar',
                'Resynchroniser mon mail et agenda électronique',
              )}
            </h3>
            <p>
              {t(
                'common:lostConnectionToMailAndCalendar',
                'La connexion à votre mail et agenda électronique a été perdue. Synchronisez à nouveau votre mail et votre agenda électronique afin de pouvoir communiquer et utiliser votre agenda directement depuis Hollo.',
              )}
            </p>
          </div>
          <div className="onboarding-footer">
            <button className="grey-btn" onClick={handleResyncLater}>
              {t('common:later')}
            </button>
            <button className="blue-btn" onClick={handleAddNylasLink}>
              {t('common:connection')}
            </button>
          </div>
        </s.OnboardingWrapper>
      </Modal> */}
    </div>
  )
}

export default withAuth(AppMain)
